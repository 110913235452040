﻿(function (w, d, $) {
  $(document).ready(function () {
    if (window.location.hash) {
      title = window.location.hash.replace('#', '');
      $('[data-toggle="tab"]').each(function (i) {
        if ($(this).text().toLowerCase() == title.toLowerCase()) {
          $(this).tab('show');
          $('html, body').animate(
            {
              scrollTop: $(this).offset().top,
            },
            2000
          );
        }
      });
    }
  });
})(window, document, jQuery);
