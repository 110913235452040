﻿(function (w, $) {
  $(function () {
    var $form = $('#modPeopleForm'),
      defaultAction = $form.attr('action'),
      $inputs = $form.find('.mod-search-input'),
      $button = $form.find('.mod-search-submit'),
      $errorWrapper = $form.find('.errors');

    $inputs
      .on('focus', function onPeopleInputFocus() {
        $errorWrapper.empty();
      })
      .on('keypress', function onPeopleInpusKeypress(ev) {
        if (ev) {
          var keyVal = ev.which ? ev.which : ev.keyCode;
          //TODO: fix w.KEYS.ENTER
          if (keyVal === 13) {
            // enter
            $button.click();
            ev.preventDefault();
          }
        }
      });

    $form.bind('submit', function onPeopleSubmit(ev) {
      ev.preventDefault();
      var isValid = false;

      $inputs.each(function eachPeopleInputValid() {
        var $input = $(this);
        // only one of the fields needs to be filled out
        if ($input.val() !== '') {
          isValid = true;
          return false; // exit each
        }
      });

      if (isValid) {
        var formAction = defaultAction + '?';
        $inputs.each(function eachPeopleInputValue() {
          var $input = $(this);
          formAction += $input.attr('name') + '=' + w.encodeURIComponent($input.val()) + '&';
        });

        formAction = formAction.substring(0, formAction.length - 1);
        w.open(
          formAction,
          this.target,
          'toolbar=yes,location=yes,scrollbars=yes,resizable=yes,top = 50, left = 50, width = 960, height = 600'
        );
      } else {
        $errorWrapper.html('<label class="error">Please enter at least one value to search.</label>');
      }
    });

    $button.on('click', function onPeopleBottonClick(ev) {
      ev.preventDefault();
      $form.trigger('submit');
    });
  });
})(this, jQuery);
