﻿(function (w, d, $) {
  $(d).ready(function () {
    //Skip Nav
    $('#skip-to-content a').on('keydown', function (e) {
      var keyCode = e.keyCode || e.which;
      if (keyCode === 13) {
        var id = $(this).attr('href');
        $(w).scrollTo(id);
        $(id).focus();
        e.preventDefault();
      }
    });
    //Remove .com specific
    //var topContainer = "<div class='content-basics back-to-top width-1280 centered'><div class='top-container'><a title='Back to Top' class='arrow-link back-to-top white' href='#top'><span class='glyphicon glyphicon-chevron-up' aria-hidden='true'></span><span class='sr-only'>Back to top</span></a></div></div>";
    //$("body").append(topContainer);
    //$(".back-to-top").hide();
    $('a.arrow-link-inline, p.arrow-link-inline > a').append(
      '<span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>'
    );

    //Fix to add text-nowrap span around last word and glyph in arrow links. Prevent's "hanging" glyph
    $('a.arrow-link:not(.back-to-top), a.arrow-link-inline, p.arrow-link-inline > a').each(function () {
      var glyph = '<span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>';
      //Do not apply to non-standard arrow links
      if ($(this).children().hasClass('glyphicon-chevron-right')) {
        var $srOnly = '';
        if ($(this).children().hasClass('sr-only')) {
          $srOnly = $(this).children('.sr-only').remove();
        }
        var html = $(this).html().split(glyph)[0].split(' ');
        //Do not wrap one word items
        if (html.length > 1) {
          html = html.slice(0, -1).join(' ') + ' <span class="text-nowrap">' + html.pop() + glyph + '</span>';
          $(this).html(html).prepend($srOnly);
        }
      }
    });

    $('.focus-link.print-page a').click(function (e) {
      e.preventDefault();
      e.stopPropagation();
      w.print();
    });

    $("a[data-popup], a[data-popup='']").on('click', function onPopupOpen(ev) {
      var $popupVar = $(this).data('popup'),
        features = '',
        newWin;

      if ($popupVar.width > 0) {
        features += 'width=' + $popupVar.width + ',';
      }

      if ($popupVar.height > 0) {
        features += 'height=' + $popupVar.height + ',';
      }

      if ($popupVar.scroll === true) {
        features += 'scrollbars=1,';
      } else if ($popupVar.scroll === false) {
        features += 'scrollbars=0,';
      }

      if ($popupVar.windowname === null) {
        $popupVar.windowname = '_new';
      }

      if (features.length > 0) {
        newWin = w.open(ev.currentTarget.href, $popupVar.windowname, features.replace(/\,$/, ''));
        if (w.focus) {
          newWin.focus();
        }
        ev.preventDefault();
      } else {
        newWin = w.open(ev.currentTarget.href);
        ev.preventDefault();
      }
    });

    $('a.close-window').on('click', function onPopupClose(ev) {
      w.close();
      ev.preventDefault();
    });
  });

  /* .com specific
    $(document).scroll(function () {
        var y = $(this).scrollTop();
        var windowHeight = $(window).height() * 2;

        if (y > windowHeight) {
            $('.back-to-top').show();
        } else {
            $('.back-to-top').hide();
        }
    });*/
})(window, document, jQuery);
