﻿(function (w, d, $) {
  $(document).ready(function () {
    function cardBottomWidth() {
      $('.card .dynamic-bottom').each(function () {
        var padding = 25,
          cardWidth = $(this).closest('.card-content').innerWidth();
        $(this).width(cardWidth);
        if (window.innerWidth >= 1200) {
          $(this)
            .find('.col.pull-left')
            .css('max-width', cardWidth - $(this).find('.col.pull-right').innerWidth() + 'px');
        } else {
          $(this).find('.col.pull-left').css('max-width', 'none');
        }
      });
    }

    setTimeout(function () {
      cardBottomWidth();
    }, 50);

    $(w).resize(cardBottomWidth);
  });
})(window, document, jQuery);
