﻿(function () {
  var cx = '016672598177003296213:m091b4zx89g';
  var gcse = document.createElement('script');
  gcse.type = 'text/javascript';
  gcse.async = true;
  gcse.src = (document.location.protocol == 'https:' ? 'https:' : 'http:') + '//cse.google.com/cse.js?cx=' + cx;
  var s = document.getElementsByTagName('script')[0];
  s.parentNode.insertBefore(gcse, s);
})();

(function (w, d, $) {
  $(document).ready(function () {
    function initSearchInput() {
      var message = 'what can we help you find?'; //"search (disabled for beta testing)";
      $('input.gsc-input').parent().prepend('<span class="glyphicon glyphicon-search"></span>');
      $('input.gsc-input').addClass('form-control search-input font-gotham-narrow');
      $('input.gsc-input').attr('placeholder', message);
      $('input.gsc-input').attr('name', 'q');
      //$("input.gsc-input").attr("disabled", "disabled");

      $('input.gsc-search-button.gsc-search-button-v2').attr('alt', 'search');

      $('.gsc-search-button').prepend(
        '<button type="submit" class="search-submit gsc-search-button gsc-search-button-v2"><span class="glyphicon glyphicon-arrow-right" aria-hidden="true"><span class="sr-only">Search</span></span></button>'
      );
      $('input.gsc-input').parents('form').attr('action', '/search/');
      $("input[name='bgresponse']").removeAttr('id'); /*duplicate Ids*/

      $('input.gsc-input').each(function (i, e) {
        $(this).on('keydown cut paste', 'input[placeholder]', function () {
          var $field = $(this);
          var $label = $(this).parent().find('label.placeholder');
          var initialValue = $field.val();
          setTimeout(function () {
            var updatedValue = $field.val();
            if (updatedValue !== initialValue) {
              $label.toggleClass('placeholder-state-hidden', updatedValue.length > 0);
            }
          }, 0);
        });

        $(e).before(
          '<label class="placeholder font-gotham-narrow" for="' + $(e).attr('id') + '">' + message + '</label>'
        );
      });
    }

    var gInt = setInterval(function () {
      if ($('input.gsc-input').length) {
        initSearchInput();
        clearInterval(gInt);
      }
    }, 200);

    $('.dropdown-large.search').on('shown.bs.dropdown', function (e) {
      e.stopPropagation();
      e.preventDefault();
      $(this).find('input.gsc-input').focus();
    });
  });

  // Hide/show the <label> when text is added/removed for IE

  $(document).on(
    'keydown.placeholder cut.placeholder paste.placeholder setValue.placeholder',
    'input[placeholder]',
    function () {
      //alert('test');
      var $field = $(this);
      var $label = $(this).parent().find('label.placeholder');
      var initialValue = $field.val();
      setTimeout(function () {
        var updatedValue = $field.val();
        if (updatedValue !== initialValue) {
          $label.toggleClass('placeholder-state-hidden', updatedValue.length > 0);
        }
      }, 0);
    }
  );
})(window, document, jQuery);
