﻿/*
------------------------------------------
Page Name:      (all)
Dependencies:	jQuery 2+
Description:    Global JS file for search box
------------------------------------------
*/

(function (w, $) {
  $(function () {
    // search form:
    var $searchForm = $('#searchForm'),
      $searchText = $searchForm.find('#searchText'),
      $searchFilter = $searchForm.find('#searchFilter'),
      $searchButton = $searchForm.find('#searchButton'),
      $errorWrapper = $searchForm.find('.errors'),
      baseHref = $searchButton.attr('href'),
      getSearchUrl = function () {
        var searchUrl = baseHref,
          searchQuery = '',
          filterVal = $searchFilter.val() || $searchFilter.find('li.active a').data('value');
        if ($searchText.val() !== '') {
          searchQuery += w.encodeURIComponent($searchText.val());
        }
        if (filterVal && filterVal !== '') {
          searchQuery = 'ALL(' + searchQuery + ')' + w.encodeURIComponent(filterVal);
        }
        if (searchQuery && searchQuery.length > 0) {
          searchUrl += '#/results/query/' + searchQuery + '/pagenumber/1/resultsperpage/20/sortby/Relevance';
          // limit to web pages?
          // += /refiners/format:%22html%22
        }
        return searchUrl;
      };

    $searchButton.on('click', function onSearchButtonClick(ev) {
      ev.preventDefault();
      var q = $searchText.val();

      $errorWrapper.empty();
      if (q && q !== '') {
        $searchButton.attr('href', getSearchUrl());
        w.open(getSearchUrl(), $searchButton.attr('target'));
      } else {
        $errorWrapper.html('<label class="error">Please enter a keyword to search.</label>');
      }
    });

    $searchText
      .on('change keyup', function onSearchTextChange(ev) {
        $searchButton.attr('href', getSearchUrl());
        if (ev) {
          var keyVal = ev.which ? ev.which : ev.keyCode;
          if (keyVal && keyVal === 13) {
            // enter
            //TODO: fix w.KEYS.ENTER
            ev.preventDefault();
            $searchButton.trigger('click');
          } else {
            $errorWrapper.empty();
          }
        }
      })
      .on('focus', function onSearchTextFocus() {
        $errorWrapper.empty();
      });

    $searchForm.bind('submit', function onSearchSubmit(ev) {
      ev.preventDefault();
    });
  });
})(this, jQuery);
